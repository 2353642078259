import { useMemo, useState } from "react";

import { isToday } from "date-fns";
import { ReactComponent as DayArrowIcon } from "../../../../assets/icons/day-arrow.svg";
import { Pack } from "../../../../components/laundry/order/Pack";
import { formatShortDate } from "../../../../utils/date";
import css from "./Day.module.scss";
import Order from "./Order";
import classNames from "classnames";

export const Day = ({ order: { date, orders, packsTotal } }) => {
  const [showOrders, setShowOrders] = useState(isToday(date));

  const handleArrowClick = () => {
    setShowOrders(!showOrders);
  };

  const collections = useMemo(() => {
    return orders.filter(({ order_type }) => order_type === "collection");
  }, [orders]);

  const regularOrders = useMemo(() => {
    return orders.filter(({ order_type }) => order_type !== "collection");
  }, [orders]);

  return (
    <>
      <div
        className={classNames(css.day, { [css["day--open"]]: showOrders })}
        onClick={handleArrowClick}
        data-testid="day"
      >
        <div className={css["day__date"]} data-testid="day-date">
          <div>{formatShortDate(date)}</div>

          <div className={css["day__arrow"]}>
            <DayArrowIcon />
          </div>
        </div>
        <div className={css["day__beds"]}>
          {packsTotal.map(({ mattress_size, quantity }, key) => (
            <Pack mattressSize={mattress_size} quantity={quantity} key={key} />
          ))}
        </div>

        <div className={css["day__count"]}>
          <p>
            <strong>
              {regularOrders.length}{" "}
              {regularOrders.length === 1 ? "Order" : "Orders"}
            </strong>
          </p>
          <p>
            <strong>
              {collections.length}{" "}
              {collections.length === 1 ? "Collection" : "Collections"}
            </strong>
          </p>
        </div>
      </div>

      {showOrders &&
        orders.map(order => (
          <Order order={order} key={order.id} className={css["day__order"]} />
        ))}
    </>
  );
};

export default Day;
