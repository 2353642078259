import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import { useMemo } from "react";
import config from "../../../config";
import css from "./Map.module.scss";

const mapContainerStyle = {
  width: "100%",
  height: 250
};

const Map = ({ lat, lng }) => {
  const center = useMemo(() => ({ lat, lng }), [lat, lng]);

  return (
    <LoadScript googleMapsApiKey={config.GOOGLE_API_KEY}>
      {lat && lng && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={15}
          mapContainerClassName={css.Map}
        >
          <Marker position={center} />
        </GoogleMap>
      )}
    </LoadScript>
  );
};

export default Map;
