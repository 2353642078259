import { useState } from "react";

import css from "./CleanImages.module.scss";

export const CleanImage = ({ thumbnailURL, onClick, imageIndex = 0 }) => {
  const [hidden, setHidden] = useState(false);
  // Don't render anything if url is incorrect
  if (hidden) {
    return null;
  }

  const handleOnClick = () => onClick(imageIndex);

  return (
    <div className={css.CleanImage} onClick={handleOnClick}>
      <img
        data-testid="clean-image"
        src={thumbnailURL}
        alt="Clean"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          // Don't show broken img icon
          setHidden(true);
        }}
      />
    </div>
  );
};
