import { useCallback } from "react";

import { Fancybox } from "@fancyapps/ui";
import { Collapsible } from "components/Collapsible";
import { groupBy } from "lodash";
import { IMAGE_TYPES } from "utils/constants";
import { config as fancyboxConfig } from "../../../utils/fancybox";
import { CleanImage } from "./CleanImage";
import css from "./CleanImages.module.scss";

export const CleanImages = ({ images = [], title, collapsed = false }) => {
  const imagesByType = groupBy(images, "type");

  const handleOpenGallery = useCallback((cleanImages, startIndex = 0) => {
    const galleryItems = cleanImages.map(({ image_url }) => ({
      src: image_url
    }));

    Fancybox.show(galleryItems, { ...fancyboxConfig, startIndex });
  }, []);

  if (images.length === 0) {
    return null;
  }

  return (
    <div>
      <h2 className={css.header}>{title}</h2>

      {Object.keys(imagesByType).map(cleanType => (
        <Collapsible
          key={cleanType}
          header={IMAGE_TYPES[cleanType]}
          collapsed={collapsed}
        >
          <div key={cleanType} className={css.CleanImages}>
            {imagesByType[cleanType].map(({ thumbnail_url }, index) => (
              <CleanImage
                key={thumbnail_url}
                imageIndex={index}
                thumbnailURL={thumbnail_url}
                onClick={() =>
                  handleOpenGallery(imagesByType[cleanType], index)
                }
              />
            ))}
          </div>
        </Collapsible>
      ))}
    </div>
  );
};
