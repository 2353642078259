import { useCallback, useEffect, useState } from "react";
import {
  CLEAN_STATUS_COMPLETED,
  CLEAN_STATUS_CONFIRMED,
  CLEAN_STATUS_DECLINED,
  CLEAN_STATUS_REQUESTED,
  CLEAN_STATUS_STARTED
} from "utils/constants";

import { isString } from "lodash";
import { updateClean } from "utils/cleans";
import Button from "../../../components/Button";
import css from "./EditableStatus.module.scss";

const EditableStatus = ({ status, cleanId, onSubmit }) => {
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
  }, [isActive]);

  const handleStatusUpdate = useCallback(
    status => {
      updateClean(cleanId, { status }).then(res => {
        if (res.error) {
          setError(res.error?.message || true);
        } else {
          setIsActive(false);
          onSubmit(res);
        }
      });
    },
    [cleanId, onSubmit]
  );

  if (isActive) {
    return (
      <div className="dont-print" data-html2canvas-ignore="true">
        <div className={css.actions}>
          {status === CLEAN_STATUS_REQUESTED && (
            <>
              <Button
                theme="solid"
                onClick={() => handleStatusUpdate(CLEAN_STATUS_DECLINED)}
                confirmAlert="Are you sure you want to decline this clean?"
              >
                Reject
              </Button>
              <Button
                theme="primary"
                onClick={() => handleStatusUpdate(CLEAN_STATUS_CONFIRMED)}
                confirmAlert
              >
                Accept
              </Button>
            </>
          )}
          {status === CLEAN_STATUS_CONFIRMED && (
            <Button
              theme="primary"
              onClick={() => handleStatusUpdate(CLEAN_STATUS_STARTED)}
              confirmAlert
            >
              Start
            </Button>
          )}
          {status === CLEAN_STATUS_STARTED && (
            <Button
              theme="primary"
              onClick={() => handleStatusUpdate(CLEAN_STATUS_COMPLETED)}
              confirmAlert
            >
              Finish
            </Button>
          )}

          <Button
            className={css["cancel-button"]}
            theme="link"
            onClick={() => setIsActive(false)}
          >
            Cancel
          </Button>
        </div>

        {error && (
          <p className={css.error}>
            {isString(error)
              ? error
              : "An error occurred, please try again later."}
          </p>
        )}
      </div>
    );
  }

  const isEditableStatus = [
    CLEAN_STATUS_REQUESTED,
    CLEAN_STATUS_STARTED,
    CLEAN_STATUS_CONFIRMED
  ].includes(status);

  return (
    <div className={css.container}>
      <p className={css.value}>{status}</p>

      {isEditableStatus && (
        <Button
          theme="link"
          className="dont-print"
          onClick={() => setIsActive(true)}
        >
          Edit
        </Button>
      )}
    </div>
  );
};

export default EditableStatus;
