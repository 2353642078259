export const WasteCollectionDays = ({ value }) => {
  if (!value) {
    return null;
  }

  const wasteCollectionDays = JSON.parse(value);

  return (
    <>
      {wasteCollectionDays.map((day, index) => (
        <span key={day}>
          {day}
          {index < wasteCollectionDays.length - 1 && ", "}
        </span>
      ))}
    </>
  );
};
