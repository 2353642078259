import { get } from "lodash";
import React from "react";
import { ReactComponent as PinIcon } from "../../../../assets/icons/pin.svg";
import Copy from "../../../../components/Copy";
import { MapLink } from "./MapLink";
import css from "./PropertyDetails.module.scss";

export default class PropertyDetails extends React.Component {
  state = {
    expanded: false
  };

  toggleExpanded = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  renderDirections = property => {
    const { expanded } = this.state;
    const instructions = get(
      property,
      "home_info.property_location_notes",
      null
    );

    if (!instructions) {
      return null;
    }

    return (
      <div className={css["property-details__more"]}>
        <button onClick={this.toggleExpanded} type="button">
          {expanded ? "Hide directions -" : "Show property directions +"}
        </button>

        {expanded && <p>{instructions}</p>}
      </div>
    );
  };

  render() {
    const { property } = this.props;

    return (
      <>
        <div className={css["property-details"]}>
          <PinIcon />

          <MapLink property={property} />
          <Copy text={`${property.full_address}, ${property.postcode}`} />
        </div>

        {this.renderDirections(property)}
      </>
    );
  }
}
