import { endOfDay, startOfDay } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { saveAs } from "file-saver";
import DatePicker from "react-datepicker";

import { createOrderUrl, getOrders, getOrdersCSV } from "../../../utils/orders";
import { DownloadCSVButton } from "./components/DownloadCSVButton";
import { OrderList } from "./components/OrderList";
import css from "./PreviousUpcoming.module.scss";
import { ReactComponent as SpinnerIcon } from "../../../assets/icons/spinner.svg";
import Button from "../../../components/Button";

export const Orders = ({
  initialStartDate,
  initialEndDate,
  csvLabel,
  pickerMaxDate,
  pickerMinDate
}) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const fetchOrders = useCallback(async (startDate, endDate) => {
    try {
      setLoading(true);
      const url = createOrderUrl(startDate, endDate);

      const { list, error } = await getOrders(url);

      setError(error);
      setList(list);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDownloadCSVButtonClick = useCallback(async () => {
    const url = createOrderUrl(startDate, endDate, { format: ".csv" });
    const { blob, error } = await getOrdersCSV(url);

    if (error) {
      setError(error);
      return;
    }

    saveAs(blob, csvLabel);
  }, [endDate, startDate, csvLabel]);

  const handleStartDateChange = date => {
    const newStartDate = startOfDay(date);
    setStartDate(newStartDate);
  };

  const handleEndDateChange = date => {
    const newEndDate = endOfDay(date);
    setEndDate(newEndDate);
  };

  useEffect(() => {
    fetchOrders(startDate, endDate);
  }, [fetchOrders, startDate, endDate]);

  return (
    <div>
      <div className={css.main}>
        <div className={css.actions}>
          <div className={css["actions__datepicker"]}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              minDate={pickerMinDate}
              maxDate={pickerMaxDate}
              selectsStart
              onChange={handleStartDateChange}
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
            />
            <div className={css["actions__decoration"]} />
            <DatePicker
              dateFormat="dd/MM/yyyy"
              minDate={pickerMinDate}
              maxDate={pickerMaxDate}
              selectsEnd
              onChange={handleEndDateChange}
              selected={endDate}
              startDate={startDate}
              endDate={endDate}
            />
          </div>

          <Button
            testid="btn-refresh-upcoming-orders"
            onClick={() => fetchOrders(startDate, endDate)}
          >
            Refresh <SpinnerIcon />
          </Button>

          <div className={css.actions__download}>
            <DownloadCSVButton onClick={handleDownloadCSVButtonClick} />
          </div>
        </div>
        <OrderList list={list} error={error} pending={loading} />
      </div>
    </div>
  );
};
