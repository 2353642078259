import { useCallback, useEffect, useRef, useState } from "react";

import Chart from "chart.js/auto";
import { Error } from "../../../components/common/Error";
import { Spinner } from "../../../components/common/Spinner";
import api from "../../../services/api";
import { formatChartDate } from "../../../utils/date";
import css from "./Forecast.module.scss";

const CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: window.innerWidth > 760,
  interaction: {
    mode: "index",
    axis: "x",
    intersect: false
  },
  plugins: {
    title: {
      display: true,
      text: "Monthly order forecast",
      color: "#000000",
      font: {
        size: 24,
        family: "Stuart-Bold, serif"
      }
    },
    legend: {
      position: "top",
      labels: {
        usePointStyle: true,
        color: "#000000",
        font: {
          size: 16,
          family: "Moderat-Light, sans-serif"
        }
      }
    }
  }
};

export const Forecast = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(false);
  // const [chartInstance, setChartInstance] = useState(null);
  const chartInstance = useRef(null);
  const chartContainer = useRef(null);

  const fetchForecast = useCallback(async () => {
    try {
      setLoading(true);

      const data = await api.get("/v1/laundry/forecast");
      const sortedData = data.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      setData(sortedData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchForecast();
  }, [fetchForecast]);

  const createChart = useCallback(() => {
    const labels = [];
    const checkouts = [];
    const checkoutForecast = [];

    data.forEach(d => {
      const date = new Date(d.date);
      labels.push(formatChartDate(date));
      checkouts.push(d.checkouts);
      checkoutForecast.push(d.forecast_orders);
    });

    const config = {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: "Current orders",
            borderColor: "#4e297d",
            backgroundColor: "#f1e6ff",
            data: checkouts,
            fill: false,
            lineTension: 0.1
          },
          {
            label: "Forecasted orders",
            borderColor: "#078fd9",
            backgroundColor: "#f8f8f8",
            data: checkoutForecast,
            fill: false,
            lineTension: 0.1
          }
        ]
      },
      options: CHART_OPTIONS
    };

    // Clear canvas if already in use
    chartInstance?.current?.destroy?.();
    chartInstance.current = new Chart(chartContainer.current, config);
  }, [data]);

  useEffect(() => {
    if (data && chartContainer?.current) {
      createChart();
    }
  }, [createChart, data]);

  return (
    <div>
      <div className={css.chart}>
        {error && <Error />}

        {loading && (
          <Spinner centered>Please wait, getting forecast of orders...</Spinner>
        )}

        <canvas ref={chartContainer} />
      </div>
    </div>
  );
};
