import classnames from "classnames";
import { useSelector } from "react-redux";
import { customColorSelector } from "../../../data/selectors/user";
import css from "./Pack.module.scss";

export const Pack = ({ mattressSize, quantity }) => {
  const customColor = useSelector(state =>
    customColorSelector(state, mattressSize)
  );

  const formattedName = mattressSize
    .replace("_", " ")
    .replace(/\b\w/g, name => name.toUpperCase());

  return (
    <div className={css.pack} data-testid="pack">
      <span className={css["pack__count"]}>{quantity}</span>
      <span
        style={{ backgroundColor: customColor }}
        className={classnames([
          css["pack__type"],
          css[`pack__type--${mattressSize}`]
        ])}
      >
        {formattedName}
      </span>
    </div>
  );
};
