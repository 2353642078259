import { Component } from "react";
import Clipboard from "react-clipboard.js";

import { ReactComponent as ClipboardIcon } from "../assets/icons/clipboard.svg";
import css from "./Copy.module.scss";

export default class Copy extends Component {
  state = {
    copied: false
  };

  onSuccess = () => {
    this.setState({
      copied: true
    });
  };

  render() {
    const { text } = this.props;
    const { copied } = this.state;

    return copied ? (
      <small className={css["copy-text"]}>Copied</small>
    ) : (
      <Clipboard
        component="a"
        className={css.copy}
        data-clipboard-text={text}
        onSuccess={this.onSuccess}
      >
        <ClipboardIcon />
      </Clipboard>
    );
  }
}
