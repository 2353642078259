export const ResetSuccess = () => {
  return (
    <div className="fixed-centered">
      <div className="card">
        <div className="card-top">
          <h3>Your password has been reset!</h3>

          <p>Please login to Housekeeping App using your new password</p>
        </div>
      </div>
    </div>
  );
};
