import { endOfDay, startOfDay } from "date-fns";

import api from "../services/api";
import { formatISODate } from "./date";
import { groupOrders } from "./groupOrders";

export const getOrders = url =>
  api
    .get(url)
    .then(list => ({ list: groupOrders(list) }))
    .catch(error => ({ error }));

export const getOrdersCSV = url =>
  api
    .get(url)
    .then(csv => ({
      blob: new Blob([csv], { type: "text/plain;charset=utf-8" })
    }))
    .catch(error => ({ error }));

export const createOrderUrl = (start, end, { format = "" } = {}) => {
  const startDate = formatISODate(startOfDay(start));
  const endDate = formatISODate(endOfDay(end));

  return `/v1/laundry/orders${format}?start_date=${startDate}&end_date=${endDate}`;
};
