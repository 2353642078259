import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import api from "../../../services/api";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

const prepareFormData = (fields, file) => {
  const formData = new FormData();

  Object.entries(fields).forEach(([name, value]) => {
    formData.append(name, value);
  });

  formData.append("file", file);

  return formData;
};

export const useImagesUpload = () => {
  const { id } = useParams();

  const [currentUpload, setCurrentUpload] = useState(0);
  const [queueSize, setQueueSize] = useState(0);

  const uploadFile = useCallback(
    async (file, values) => {
      setCurrentUpload(previous => previous + 1);

      // Create a pre-signed post
      const { filename, original: presignedPost } = await api.post(
        "/v1/cleaners/cleans/images-upload",
        null,
        {
          content_type: file.type,
          extension: file.name.split(".").pop().toLowerCase()
        },
        { "X-Airsorted-Idempotency-Key": uuidv4() }
      );

      // Upload to S3
      const formData = prepareFormData(presignedPost.fields, file);
      await axios.post(presignedPost.url, formData, {
        "Content-Type": "multipart/form-data"
      });

      // Create the image in the database
      const newPhoto = await api.post(
        `/v1/cleaners/cleans/${id}/images`,
        null,
        { filename, ...values },
        { "X-Airsorted-Idempotency-Key": uuidv4() }
      );

      return newPhoto;
    },
    [id]
  );

  return {
    uploadFile,
    currentUpload,
    setCurrentUpload,
    queueSize,
    setQueueSize
  };
};
