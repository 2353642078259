import { get } from "lodash";

const getGoogleMapsUrl = (lat, lng) =>
  `https://maps.google.com/?q=${lat},${lng}`;

const getFloorLabel = property => {
  const floor = get(property, "home_info.unit_floor", null);

  if (floor === null) {
    return null;
  }

  if (floor === -1) {
    return "(basement)";
  }

  if (floor === 0) {
    return "(ground floor)";
  }

  return `(floor ${floor})`;
};

export const getPropertyAddressWithFloor = property => {
  const floorLabel = getFloorLabel(property);

  return `${property.full_address}, ${property.postcode}`.concat(
    floorLabel ? ` ${floorLabel}` : ""
  );
};

export const MapLink = ({ property }) => {
  if (!property.home_info) {
    return `${property.full_address}, ${property.postcode}`;
  }

  const { property_lat, property_lng } = property.home_info;

  return (
    <a
      href={getGoogleMapsUrl(property_lat, property_lng)}
      target="_blank"
      rel="noreferer noreferrer"
    >
      {getPropertyAddressWithFloor(property)}
    </a>
  );
};
