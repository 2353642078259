import { Field, Form, Formik } from "formik";
import { useCallback, useState } from "react";

import { Link } from "react-router-dom";
import Button from "../../components/Button";
import DefaultError from "../../components/common/DefaultError";
import api from "../../services/api";
import { useQuery } from "../../utils/useQuery";
import { newPasswordValidationSchema } from "../login/validation_schemas";

export const ResetPassword = () => {
  const query = useQuery();
  const [submitted, setSubmitted] = useState(false);

  const token = query.get("token");

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors }) => {
      const { newPassword: password } = values;

      api
        .put("/v1/laundry/user/reset_password", null, {
          token,
          password
        })
        .then(() => {
          setSubmitted(true);
        })
        .catch(e => {
          setErrors({
            authError:
              e.error.code === 403
                ? e.error.message
                : "Something went wrong, please try again"
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [token]
  );

  if (submitted) {
    return (
      <div>
        <h3>Your password has been reset!</h3>
        <div>
          <p>Please login with you new password</p>
        </div>
        <Link to="/login">Login</Link>
      </div>
    );
  }

  return (
    <div className="fixed-centered">
      <div className="card">
        <h3 className="card-top">Please enter a new password</h3>
        <Formik
          initialValues={{ newPassword: "", newPasswordConfirmation: "" }}
          onSubmit={handleSubmit}
          validationSchema={newPasswordValidationSchema}
        >
          {({ errors, isSubmitting }) => (
            <Form className="card-body">
              <label htmlFor="newPassword">New password</label>

              <div className="field">
                <Field
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  placeholder="Your new password"
                />
                {errors.newPassword && (
                  <DefaultError>{errors.newPassword}</DefaultError>
                )}
              </div>

              <div className="field">
                <Field
                  type="password"
                  name="newPasswordConfirmation"
                  placeholder="Repeat your new password"
                />
                {errors.newPasswordConfirmation && (
                  <DefaultError>{errors.newPasswordConfirmation}</DefaultError>
                )}
              </div>

              <div className="card-actions">
                <Button type="submit" theme="solid" disabled={isSubmitting}>
                  Submit
                </Button>
                {errors.authError && (
                  <DefaultError>{errors.authError}</DefaultError>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
