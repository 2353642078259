import { currentUserType, userIsLoggedIn } from "data/selectors/user";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation
} from "react-router-dom";
import { USER_TYPE_CLEANS, USER_TYPE_LAUNDRY } from "utils/constants";

import { MainContainer as CleansDashboardContainer } from "./components/cleans/main-container/MainContainer";
import { MainContainer as LaundryDashboardContainer } from "./components/laundry/main-container/MainContainer";
import { AuthRoute } from "./components/nav/AuthRoute";
import { ForgotPassword } from "./scenes/forgot-password/ForgotPassword";
import { Login } from "./scenes/login/Login";
import { TokenLogin } from "./scenes/login/TokenLogin";
import { ResetPassword } from "./scenes/reset-password/ResetPassword";
import { ResetPasswordCleaner } from "./scenes/reset-password/ResetPasswordCleaner";
import { DeleteAccount } from "scenes/delete-account/DeleteAccount";

export const AppRouter = () => (
  <BrowserRouter>
    <AppRouterInternal />
  </BrowserRouter>
);

const AppRouterInternal = () => {
  const userType = useSelector(currentUserType);
  const { search } = useLocation();
  const isLoggedIn = useSelector(userIsLoggedIn);

  const redirectToLogin = useMemo(
    () => (
      <Switch>
        <Redirect
          to={{ pathname: "/login", search: `?${USER_TYPE_CLEANS}` }}
          from={`/${USER_TYPE_CLEANS}*`}
        />
        <Redirect
          to={{ pathname: "/login", search: `?${USER_TYPE_LAUNDRY}` }}
          from={`/${USER_TYPE_LAUNDRY}*`}
        />
        <Redirect to={{ pathname: "/login", search }} />
      </Switch>
    ),
    [search]
  );

  const routesForCurrentUser = useMemo(() => {
    if (!isLoggedIn) {
      return null;
    }

    const homeComponentsForUserType = {
      [USER_TYPE_LAUNDRY]: LaundryDashboardContainer,
      [USER_TYPE_CLEANS]: CleansDashboardContainer
    };

    const HomeComponent = homeComponentsForUserType[userType];
    const homePath = `/${userType}`;

    return (
      <Switch>
        <AuthRoute path={homePath} component={HomeComponent} />
        {/* When trying to access any other route */}
        <Redirect from="*" to={homePath} />
      </Switch>
    );
  }, [isLoggedIn, userType]);

  const routesForAnonymousUser = useMemo(() => {
    // No need to check for isLoggedIn here as the switch inside homeComponentsForUserType
    // will catch any other route and redirect to home e.g. /cleans
    // So if user is logged in and tries to access /login, they will be redirected to /cleans

    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route
          exact
          path={[
            `/:selectedDashboard(${USER_TYPE_CLEANS}|${USER_TYPE_LAUNDRY})/delete-account`,
            "/houstkeeping-app/delete-account"
          ]}
          component={DeleteAccount}
        />
        <Route
          exact
          path={`/:selectedDashboard(${USER_TYPE_CLEANS}|${USER_TYPE_LAUNDRY})/forgot-password`}
          component={ForgotPassword}
        />
        {redirectToLogin}
      </Switch>
    );
  }, [redirectToLogin]);

  return (
    <Switch>
      <Route exact path="/login/:token" component={TokenLogin} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route
        exact
        path="/cleaner-reset-password"
        component={ResetPasswordCleaner}
      />

      {routesForCurrentUser}
      {routesForAnonymousUser}
    </Switch>
  );
};
