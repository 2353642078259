import { useCallback, useEffect, useState } from "react";

import { isEqual } from "lodash";
import { CLEANS_DEFAULT_LIMIT } from "utils/constants";
import { getCleans } from "../../../utils/cleans";
import { List } from "../components/List";

const UPCOMING_CLEANS_URL = "/v1/cleaners/cleans/upcoming";

const Upcoming = () => {
  const [cleanList, setCleanList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchCleans = useCallback(async (cleanList, offset) => {
    const { list, error } = await getCleans(UPCOMING_CLEANS_URL, offset);
    const newList = [...new Set([...cleanList, ...list])];
    setError(error);

    setCleanList(newList);
    setHasMore(
      !isEqual(cleanList, newList) && !(newList.length < CLEANS_DEFAULT_LIMIT)
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchCleans([], 0).then(() => setLoading(false));
  }, [fetchCleans]);

  const fetchMore = () => {
    const newOffset = offset + CLEANS_DEFAULT_LIMIT;
    fetchCleans(cleanList, newOffset).then(() => {
      setOffset(newOffset);
    });
  };

  return (
    <List
      error={error}
      fetchMore={fetchMore}
      hasMore={hasMore}
      list={cleanList}
      loading={loading}
    />
  );
};

export default Upcoming;
