/* eslint-disable jsx-a11y/control-has-associated-label */
import { humanize } from "../../utils/string";

export const SelectOptions = ({
  includeBlank = true,
  disabled = false,
  options
}) => {
  const sanitizedOptions = Array.isArray(options)
    ? options
    : Object.entries(options);

  return (
    <>
      {includeBlank && <option value="" />}
      {sanitizedOptions.map(option => {
        const [value, label] = Array.isArray(option) ? option : [option];

        return (
          <option key={value} value={value} disabled={disabled}>
            {label || humanize(value)}
          </option>
        );
      })}
    </>
  );
};
