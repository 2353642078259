import InfiniteScroll from "react-infinite-scroll-component";
import { Error } from "../../../components/common/Error";
import Spinner from "../../../components/common/Spinner";
import css from "./List.module.scss";
import { ListItem } from "./ListItem";

export const List = ({ error, fetchMore, hasMore, list, loading }) => {
  if (loading) {
    return <Spinner centered>Please wait, loading cleans...</Spinner>;
  }

  if (error) {
    return <Error />;
  }

  return (
    <InfiniteScroll
      next={fetchMore}
      hasMore={hasMore}
      loader={
        <div className={css.SpinnerWrapper}>
          <Spinner />
        </div>
      }
      dataLength={list.length}
    >
      <div className={css.List}>
        <table className={css.table}>
          <thead>
            <tr>
              <th className={css.heading}>Date</th>
              <th className={css.heading}>Time</th>
              <th className={css.heading}>Status</th>
              <th className={css.heading}>Home code</th>
              <th className={css.heading}>Property address</th>
              <th className={css.heading}>Label</th>
            </tr>
          </thead>
          <tbody>
            {list.map(clean => (
              <ListItem clean={clean} key={clean.id} />
            ))}
          </tbody>
        </table>
      </div>
    </InfiniteScroll>
  );
};
