import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { useFetchUser } from "components/useFetchUser";
import { useApiErrorsInterceptor } from "utils/useApiErrorsInterceptor";
import { Account } from "../../../scenes/laundry/account/Account";
import { Forecast } from "../../../scenes/laundry/orders/Forecast";
import Previous from "../../../scenes/laundry/orders/Previous";
import { Upcoming } from "../../../scenes/laundry/orders/Upcoming";
import { Spinner } from "../../common/Spinner";
import { Header } from "./Header";
import css from "./MainContainer.module.scss";

export const MainContainer = () => {
  useApiErrorsInterceptor();
  let { path } = useRouteMatch();
  const { loading } = useFetchUser("/v1/laundry/user/me");

  if (loading) {
    return <Spinner centered />;
  }

  return (
    <>
      <Header />

      <div className={css.container}>
        <Switch>
          <Redirect exact from="/laundry" to="/laundry/upcoming" />
          <Route exact path={[path, `${path}/upcoming`]} component={Upcoming} />
          <Route exact path={`${path}/previous`} component={Previous} />
          <Route exact path={`${path}/forecast`} component={Forecast} />
          <Route
            exact
            path={[`${path}/account`, `${path}/delete-account`]}
            component={Account}
          />
        </Switch>
      </div>
    </>
  );
};
