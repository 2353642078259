import "./App.scss";

import { AppRouter } from "AppRouter";
import { Provider } from "react-redux";

import store from "./data/store";

const App = () => {
  if (window.Cypress) {
    window.store = store;
  }

  return (
    <Provider store={store}>
      <div className="App">
        <AppRouter />
      </div>
    </Provider>
  );
};

export default App;
