import classNames from "classnames";
import { Formik } from "formik";
import { useState } from "react";
import Button from "../../../components/Button";
import css from "./Editable.module.scss";

const Editable = ({
  error,
  value,
  onSubmit,
  emptyValueText,
  inplace = false
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleSubmit = ({ value }) => {
    onSubmit(value);
    setIsActive(false);
  };

  const handleBlur = () => {
    setIsActive(false);
  };

  if (isActive || error) {
    return (
      <Formik
        initialValues={{ value: value || "" }}
        onSubmit={values => handleSubmit(values)}
      >
        {({ handleSubmit, handleChange, values }) => (
          <>
            <form
              className={classNames(css.container, {
                [css.container__inplace]: inplace
              })}
              onSubmit={handleSubmit}
            >
              <input
                autoFocus
                type="text"
                className={css.input}
                onChange={handleChange}
                autoComplete="off"
                onBlur={handleBlur}
                value={values.value}
                name="value"
              />

              <Button theme="solid" type="submit">
                Submit
              </Button>
            </form>
            {error && (
              <p className={css.error}>
                An error occurred, please try again later.
              </p>
            )}
          </>
        )}
      </Formik>
    );
  }

  return (
    <div
      className={classNames(css.container, {
        [css.container__inplace]: inplace
      })}
    >
      <p className={css.value}>{value}</p>

      <Button
        theme={inplace ? "text" : "link"}
        className={classNames("dont-print", {
          [css["empty-value"]]: !value
        })}
        data-html2canvas-ignore="true"
        onClick={() => setIsActive(true)}
      >
        {inplace ? value || emptyValueText : "Edit"}
      </Button>
    </div>
  );
};

export default Editable;
