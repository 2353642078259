const TEST_ENV = "test";
const DEVELOPMENT_ENV = "development";
const STAGING_ENV = "staging";
const PRODUCTION_ENV = "production";
const STAGING_URL = "supplier.staging.houst.com";

const environmentName = () => {
  if (window.location.host === STAGING_URL) {
    return STAGING_ENV;
  }

  return process.env.NODE_ENV || DEVELOPMENT_ENV;
};

const API_HOST = {
  [TEST_ENV]: "http://localhost:3001",
  [DEVELOPMENT_ENV]: "http://localhost:3001",
  [STAGING_ENV]: "https://api.staging.houst.com",
  [PRODUCTION_ENV]: "https://api.houst.com"
}[environmentName()];

const GOOGLE_API_KEY = "AIzaSyAT3pniXyhXz6sSewNX9Np8dsiijiuUN0Y";

const config = {
  ...process.env,
  API_HOST,
  GOOGLE_API_KEY
};

export default config;
