import "./modals.scss";
import SweetAlert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const Swal = withReactContent(SweetAlert2);

export const modal = Swal.mixin({
  customClass: {
    container: "modal-container"
  },
  showCancelButton: true,
  showConfirmButton: true,
  reverseButtons: true,
  focusCancel: true
});

export const toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true
});

export const showSuccessToast = title => {
  toast.fire({
    type: "success",
    title
  });
};

export const showErrorToast = (title, options = {}) => {
  toast.fire({
    type: "error",
    title,
    timer: 10000,
    ...options
  });
};

// Action buttons for SweetAlert modals that looks like the default buttons but
// it can be placed and controlled from the react component.
export const SwalActions = ({
  disabled = false,
  cancelText = "Cancel",
  onCancel,
  confirmText = "Ok",
  onConfirm
}) => (
  <div className="swal2-actions" style={{ display: "flex", fontSize: "0.9em" }}>
    <button
      type="button"
      onClick={onCancel}
      disabled={disabled}
      className="swal2-cancel swal2-styled"
    >
      {cancelText}
    </button>

    <button
      type="button"
      onClick={onConfirm}
      disabled={disabled}
      className="swal2-confirm swal2-styled"
    >
      {confirmText}
    </button>
  </div>
);
