const VIDEO_TEMPLATE = `
<video class="fancybox__html5video" controls poster="{{poster}}" src="{{src}}">
  <source src="{{src}}" type="video/mp4" />
  Sorry, your browser doesn't support embedded videos, 
  <a href="{{src}}" download>download</a> and watch with your video player.
  <p class="download-note">Video not showing up? It's format may not be supported by your browser. <a target="_blank" href="{{src}}" download>Download</a> and watch it with your video player or try a different browser.</p>
</video>`;

export const config = {
  groupAll: true,
  Html: {
    video: {
      autoplay: false
    },
    html5video: {
      tpl: VIDEO_TEMPLATE
    }
  },
  Toolbar: {
    autoEnable: false,
    display: [
      { id: "counter", position: "center" },
      "zoom",
      "download",
      "delete",
      "close"
    ]
  }
};
