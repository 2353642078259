export const LAUNDRY_ORDER_STATUS_CANCELLED = "cancelled";

export const CLEAN_FRIDGE_REMOVE_ALL = "remove_all";
export const CLEAN_FRIDGE_LEAVE_ALL = "leave_all";
export const CLEAN_FRIDGE_REMOVE_PERISHABLES = "remove_perishables";

export const TASK_TYPE_REMOTE = "remote";
export const TASK_TYPE_STANDARD = "standard";
export const TASK_TYPE_POST_CLEAN_IMAGES = "images";
export const TASK_TYPE_PROPERTY = "property";

export const ACCESS_TYPE_KEYNEST = "keynest";
export const ACCESS_TYPE_KEYLESS_DIGITAL_LOCK = "keyless digital lock";
