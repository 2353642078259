import { useCallback, useState } from "react";
import css from "./account.module.scss";
import api from "services/api";
import { useDispatch } from "react-redux";
import { logout } from "data/actions/user";
import Button from "components/Button";
import DefaultError from "components/common/DefaultError";
import { FormGroup } from "components/common/FormGroup";
import { Form, Formik } from "formik";

export const Account = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(undefined);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(() => {
    api
      .delete("/v1/cleaners/user")
      .then(() => {
        setSubmitting(false);
        dispatch(logout());
      })
      .catch(e => {
        setSubmitting(false);

        if (e.error.code === 403) {
          setError(e.error.message);
        } else {
          setError(
            "There was a problem closing your account. Please contact your support team."
          );
        }
      });
  }, [dispatch]);

  return (
    <div className={css["account-container"]}>
      <h3>Remove your account</h3>

      <Formik initialValues={{ confirmation: false }} onSubmit={handleSubmit}>
        {({ values }) => (
          <Form>
            <div>
              {error && <DefaultError>{error}</DefaultError>}
              <p>
                <strong>Warning!</strong> This form will delete your account
                together with your personal data. You will not be able to
                recover your account.
              </p>

              <FormGroup
                label="I understand this operation is irreversible and I wish to continue"
                name="confirmation"
                type="checkbox"
              />

              <Button
                theme="danger"
                testid="delete-account-button"
                onClick={handleSubmit}
                disabled={!values.confirmation || isSubmitting}
              >
                Delete my account forever
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
