import { endOfDay, startOfMonth, subMonths } from "date-fns";

import { Orders } from "./Orders";

export const Previous = () => {
  const currentDate = new Date();
  const startOfCurrentMonth = startOfMonth(currentDate);
  const startMinDate = subMonths(startOfCurrentMonth, 12);

  return (
    <Orders
      initialStartDate={startOfCurrentMonth}
      initialEndDate={endOfDay(currentDate)}
      csvLabel="previous_orders.csv"
      pickerMaxDate={currentDate}
      pickerMinDate={startMinDate}
    />
  );
};

export default Previous;
