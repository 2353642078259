import { Error } from "../../../../components/common/Error";
import Spinner from "../../../../components/common/Spinner";
import Day from "./Day";
import css from "./OrderList.module.scss";

export const OrderList = ({ list, error, pending }) => {
  if (error) {
    console.warn(error);
    return <Error />;
  }

  if (pending) {
    return <Spinner centered>Please wait, getting laundry orders...</Spinner>;
  }

  return (
    <>
      {list.length === 0 ? (
        <p className={css["empty"]}>
          <strong>No orders for this time period</strong>
        </p>
      ) : (
        <div className={css["order-list"]}>
          {list.map((order, key) => (
            <Day key={key} order={order} />
          ))}
        </div>
      )}
    </>
  );
};
