import classNames from "classnames";
import { useState } from "react";

import Button from "./Button";
import css from "./Collapsible.module.scss";

export const Collapsible = ({ collapsed: collapsedProp, header, children }) => {
  const [collapsed, setCollapsed] = useState(collapsedProp);

  return (
    <div data-testid="expandable-button">
      <Button
        theme="text"
        className={classNames(css.header, {
          [css["headerHidden"]]: collapsed
        })}
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        {header}
      </Button>

      <div className={classNames({ [css.hidden]: collapsed })}>{children}</div>
    </div>
  );
};
