import { Pack } from "../../../../components/laundry/order/Pack";
import css from "./ShowPreset.module.scss";

export const ShowPreset = () => (
  <div className={css["show-preset"]}>
    <div>
      <Pack mattressSize="small_single" quantity="1" />

      <Pack mattressSize="single" quantity="1" />

      <Pack mattressSize="small_double" quantity="1" />

      <Pack mattressSize="double" quantity="1" />
    </div>
    <div>
      <Pack mattressSize="king" quantity="1" />

      <Pack mattressSize="super_king" quantity="1" />

      <Pack mattressSize="queen" quantity="1" />
    </div>
  </div>
);
