import { parseDateTime } from "./dateParser";

export const checkIfCodeIsAvailable = cleanStart => {
  const today = new Date();
  const codeStartDate = parseDateTime(cleanStart);
  const codeEndDate = parseDateTime(cleanStart);
  codeStartDate.setDate(codeStartDate.getDate() - 1);
  codeEndDate.setHours(23, 59, 59, 0);

  return today >= codeStartDate && today <= codeEndDate;
};
