import { useCallback, useMemo } from "react";
import {
  showErrorToast,
  showSuccessToast
} from "../../../components/common/modals";

import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import { Spinner } from "../../../components/common/Spinner";
import { confirmCleanType } from "./confirmCleanType";
import { useImagesUpload } from "./useImagesUpload";
import css from "./CleanImagesUpload.module.scss";
import Button from "components/Button";

const showSuccessMessage = amount => {
  if (amount === 1) {
    return showSuccessToast("Image uploaded");
  }
  return showSuccessToast(`${amount} images uploaded`);
};

export const CleanImagesUpload = ({ onUploadFinish }) => {
  const {
    uploadFile,
    currentUpload,
    setCurrentUpload,
    queueSize,
    setQueueSize
  } = useImagesUpload();

  const submitFiles = useCallback(
    async (fileList, values) => {
      let response;
      // eslint-disable-next-line no-restricted-syntax
      for (const file of fileList) {
        // eslint-disable-next-line no-await-in-loop
        response = await uploadFile(file, values);
      }
      return response;
    },
    [uploadFile]
  );

  const handleSubmit = useCallback(
    async fileList => {
      const { confirmed, values } = await confirmCleanType();

      if (!confirmed) {
        return;
      }

      setQueueSize(fileList.length);

      submitFiles(fileList, values)
        .then(() => {
          showSuccessMessage(fileList.length);
          onUploadFinish();
        })
        .catch(error => {
          showErrorToast(
            error?.error?.message || "Something went wrong, please try again"
          );
        })
        .finally(() => {
          setQueueSize(0);
          setCurrentUpload(0);
        });
    },
    [onUploadFinish, setCurrentUpload, setQueueSize, submitFiles]
  );

  const {
    getRootProps,
    getInputProps,
    open: handleSelectFiles,
    isDragActive,
    isDragReject
  } = useDropzone({
    accept: {
      "image/jpg": [],
      "image/jpeg": [],
      "image/heic": [],
      "image/heif": []
    },
    onDrop: handleSubmit
  });

  const statusMessage = useMemo(() => {
    if (queueSize > 0) {
      return (
        <Spinner>
          <span>{`Uploading ${currentUpload}/${queueSize}`}</span>
        </Spinner>
      );
    }

    if (isDragActive) {
      return isDragReject ? (
        <p>Files of those types will be rejected</p>
      ) : (
        <p>Drop your files here...</p>
      );
    }

    return (
      <>
        Drag your photos to start uploading <br />
        <span className="text-info">
          (Only *.jpeg, *.jpeg and *.heic images will be accepted)
        </span>
        <p className={css["with-line"]}>
          <span className={css.or}> or </span>
        </p>
        <Button theme="solid" onClick={handleSelectFiles}>
          Browse files
        </Button>
      </>
    );
  }, [currentUpload, handleSelectFiles, isDragActive, isDragReject, queueSize]);

  return (
    <div
      {...getRootProps()}
      className={classNames(css.container, {
        [css.reject]: isDragReject,
        [css.active]: isDragActive
      })}
    >
      {statusMessage}

      <input {...getInputProps()} />
    </div>
  );
};
