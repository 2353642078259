import { ReactComponent as SVG } from "../../assets/icons/logo.svg";
import css from "./Logo.module.scss";

export const Logo = () => {
  return (
    <div className={css["logo"]}>
      <SVG />
    </div>
  );
};
