import * as yup from "yup";

export const emailValidationSchema = yup.object().shape({
  email: yup.string().trim().required("Email required").email("Email invalid")
});

export const passwordValidationSchema = yup.object().shape({
  password: yup.string().required("Password required")
});

export const newPasswordValidationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("New password required"),
  newPasswordConfirmation: yup
    .string()
    .oneOf([yup.ref("newPassword")], "Passwords don't match")
    .required("Confirm new password")
});

export const loginValidationSchema = yup
  .object()
  .concat(emailValidationSchema)
  .concat(passwordValidationSchema);

export const changePasswordValidationSchema = yup
  .object()
  .shape({
    currentPassword: yup.string().required("Current password required")
  })
  .concat(newPasswordValidationSchema);
