import axios from "axios";

import config from "../config";

export const NETWORK_ERROR_MESSAGE = "Network Error";
export const TIMEOUT_ERROR_CODE = "ECONNABORTED";
const DEFAULT_ERROR_MESSAGE = "Server error";

export const APIHostNotSetError = {
  name: "APIHostNotSetError",
  message: "No host set"
};

const requestErrorMessage = error => {
  if (
    (error.message === NETWORK_ERROR_MESSAGE && error.code === undefined) ||
    error.code === TIMEOUT_ERROR_CODE
  ) {
    return NETWORK_ERROR_MESSAGE;
  }

  return error.response
    ? sanitizeErrorResponse(error.response.data ?? {})
    : DEFAULT_ERROR_MESSAGE;
};

export const sanitizeErrorResponse = ({ code, message, errors }) => {
  if (message && typeof message === "string") {
    return { code, message };
  }

  return { code, message: "Unknown error" };
};

export class ApiManager {
  constructor({ host = null, token = null } = {}) {
    this.setHost(host);
    this.setToken(token);
  }

  setToken(token) {
    this.token = token;
  }

  setHost(host) {
    this.host = host;
  }

  request(method, route, params, body = {}, headers = {}) {
    if (!this.host && !route.startsWith("http")) {
      throw APIHostNotSetError;
    }

    if (method !== "GET") {
      headers = {
        // add content type header if not present
        "Content-Type": "application/json",
        ...headers
      };
    }

    if (this.token) {
      headers["X-Airsorted-Oauth-Token"] = this.token;
    }

    const url = this.host ? `${this.host}${route}` : route;

    return axios({
      url,
      method,
      params,
      headers,
      data: body
    }).then(
      response => response.data,
      error => {
        const statusCode = error.response?.status;

        return Promise.reject({
          statusCode,
          error: requestErrorMessage(error)
        });
      }
    );
  }

  get = (...args) => this.request("GET", ...args);
  post = (...args) => this.request("POST", ...args);
  put = (...args) => this.request("PUT", ...args);
  patch = (...args) => this.request("PATCH", ...args);
  delete = (...args) => this.request("DELETE", ...args);
}

const api = new ApiManager({ host: config.API_HOST });

export default api;
