import {
  CLEAN_FRIDGE_LEAVE_ALL,
  CLEAN_FRIDGE_REMOVE_ALL,
  CLEAN_FRIDGE_REMOVE_PERISHABLES
} from "../../../utils/types";

export const Fridge = ({ instructions }) => {
  switch (instructions) {
    case CLEAN_FRIDGE_LEAVE_ALL:
      return "Do not throw away any items from the fridge.";
    case CLEAN_FRIDGE_REMOVE_ALL:
      return "Remove all items from fridge so it's completely empty.";
    case CLEAN_FRIDGE_REMOVE_PERISHABLES:
      return "Throw away perishables and expired food only.";
    default:
      return "";
  }
};
