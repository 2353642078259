import axios from "axios";
import { logout } from "data/actions/user";
import { identity } from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Logs out user if session expires or is invalid
export const useApiErrorsInterceptor = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    axios.interceptors.response.use(identity, error => {
      const statusCode = error.response?.status;

      if (statusCode === 401) {
        dispatch(logout());
      }

      return Promise.reject(error);
    });
  }, [dispatch]);
};
