import * as yup from "yup";

import { ErrorMessage, Form, Formik } from "formik";

import { FormGroup } from "../../../components/common/FormGroup";
import { modal } from "../../../components/common/modals";
import { SelectOptions } from "../../../components/common/SelectOptions";
import { IMAGE_TYPES_OPTIONS } from "../../../utils/constants";
import css from "./confirmCleanType.module.scss";
import Button from "components/Button";

const GENERAL_TYPES = Object.keys(IMAGE_TYPES_OPTIONS).filter(
  t => !t.startsWith("location_")
);
const PRESENTATION_TYPES = Object.keys(IMAGE_TYPES_OPTIONS).filter(t =>
  t.startsWith("location_")
);

const validationSchema = yup.object().shape({
  type: yup.string().required(),
  receipt_amount: yup.string().when("type", (value, schema) =>
    value === "receipt"
      ? schema
          .required("Receipt amount is required")
          .matches(/^\d+(\.\d{1,2})?$/, {
            message: "Receipt amount is invalid"
          })
      : schema
  )
});

export const confirmCleanType = () => {
  return new Promise(resolve => {
    const handleSubmit = values => {
      resolve({ confirmed: true, values });
      modal.close();
    };

    const handleCancel = () => modal.clickCancel();

    modal.fire({
      html: (
        <Formik
          initialValues={{ type: "other" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <>
              <h3>Please select clean image type for uploaded files</h3>

              <Form>
                <div className="fields">
                  <FormGroup label="Type" name="type" component="select">
                    <optgroup label="General Image">
                      <SelectOptions options={GENERAL_TYPES} />
                    </optgroup>

                    <optgroup label="Presentation Image">
                      <SelectOptions
                        includeBlank={false}
                        options={PRESENTATION_TYPES}
                      />
                    </optgroup>
                  </FormGroup>
                  <ErrorMessage name="type">
                    {message => <div className="error">{message}</div>}
                  </ErrorMessage>

                  {values.type === "receipt" && (
                    <FormGroup label="Receipt amount" name="receipt_amount" />
                  )}
                </div>

                <div className={css.actions}>
                  <Button type="button" theme="link" onClick={handleCancel}>
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    theme="solid"
                    className="btn btn-submit"
                  >
                    Upload
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      ),
      animation: false,
      showConfirmButton: false,
      showCancelButton: false,
      width: 800
    });
  });
};
