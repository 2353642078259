import { isEmpty, sortBy } from "lodash";

import { formatHour } from "../../../utils/date";
import { LAUNDRY_ORDER_STATUS_CANCELLED } from "../../../utils/types";

export const LaundryOrders = ({ orders = [] }) => {
  if (isEmpty(orders)) {
    return null;
  }

  // get last laundry order
  const sorted = sortBy(orders, ["created_at"]).reverse();
  const laundry_order = sorted[0];

  if (laundry_order.status === LAUNDRY_ORDER_STATUS_CANCELLED) {
    return null;
  }

  return (
    <>
      Delivered by {laundry_order.laundry_provider.name}. Between{" "}
      {formatHour(laundry_order.delivery_window_start)} and{" "}
      {formatHour(laundry_order.delivery_window_end)}
    </>
  );
};
