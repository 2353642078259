import { chain, get } from "lodash";
import { USER_TYPE_CLEANS, USER_TYPE_LAUNDRY } from "utils/constants";

export const userSelector = state => get(state, "user");

export const isCustomPresetSelector = state => {
  const { user } = state.user;

  return (
    chain(user)
      .pickBy((value, key) => key.startsWith("color_") && Boolean(value))
      .keys()
      .value().length > 0
  );
};

export const currentUserType = state => state.user?.userType;

export const userIsLoggedIn = state =>
  Boolean(state.user.authToken) &&
  [USER_TYPE_CLEANS, USER_TYPE_LAUNDRY].includes(currentUserType(state));

export const customColorSelector = (state, mattressSize) => {
  const { profile } = state.user;
  return profile[`color_${mattressSize}`];
};
