import css from "./Error.module.scss";

export const Error = () => {
  return (
    <div className={css.Error}>
      <p className={css.Error__text}>
        An error has occurred while fetching data, please refresh and try again.
      </p>
    </div>
  );
};
