import { Field, Form, Formik } from "formik";
import { useCallback } from "react";

import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import DefaultError from "../../../components/common/DefaultError";
import api from "../../../services/api";
import { changePasswordValidationSchema } from "../../login/validation_schemas";

export const ChangePassword = () => {
  const history = useHistory();

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors }) => {
      const { currentPassword, newPassword } = values;

      api
        .put("/v1/laundry/user/me/auth", null, {
          current_password: currentPassword,
          password: newPassword
        })
        .then(res => {
          setSubmitting(false);
          history.push({ pathname: "/" });
        })
        .catch(e => {
          setSubmitting(false);

          if (e.error.code === 403) {
            setErrors({ error: e.error.message });
          } else {
            setErrors({ error: "Something went wrong. Please try again." });
          }
        });
    },
    [history]
  );

  return (
    <Formik
      initialValues={{
        currentPassword: "",
        newPassword: "",
        newPasswordConfirmation: ""
      }}
      onSubmit={handleSubmit}
      validationSchema={changePasswordValidationSchema}
    >
      {({ errors, isSubmitting }) => (
        <Form className="form">
          <label htmlFor="currentPassword">Current password</label>

          <div className="field">
            <Field
              type="password"
              name="currentPassword"
              id="currentPassword"
              placeholder="Your current password"
            />
            {errors.currentPassword && (
              <DefaultError>{errors.currentPassword}</DefaultError>
            )}
          </div>

          <label htmlFor="newPassword">New password</label>

          <div className="field">
            <Field
              type="password"
              name="newPassword"
              id="newPassword"
              placeholder="Your new password"
            />
            {errors.newPassword && (
              <DefaultError>{errors.newPassword}</DefaultError>
            )}
          </div>

          <div className="field">
            <Field
              type="password"
              name="newPasswordConfirmation"
              placeholder="Repeat your new password"
            />
            {errors.newPasswordConfirmation && (
              <DefaultError>{errors.newPasswordConfirmation}</DefaultError>
            )}
          </div>

          <div className="field">
            <Button type="submit" theme="solid" disabled={isSubmitting}>
              Change password
            </Button>
          </div>

          <div className="field">
            <DefaultError>{errors.error}</DefaultError>
          </div>
        </Form>
      )}
    </Formik>
  );
};
