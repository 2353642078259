import { Field, Form, Formik } from "formik";
import { useCallback } from "react";
import { USER_TYPE_CLEANS, USER_TYPE_LAUNDRY } from "utils/constants";

import classNames from "classnames";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import DefaultError from "../../components/common/DefaultError";
import { Logo } from "../../components/common/Logo";
import { receiveLogin } from "../../data/actions/user";
import api from "../../services/api";
import css from "./login.module.scss";
import { loginValidationSchema } from "./validation_schemas";
import { useDispatch } from "react-redux";

export const LoginForm = ({
  selectedDashboard,
  onUserTypeSwitch,
  showUserTypeSwitch = true,
  headerContent,
  submitButtonText
}) => {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors }) => {
      const endpoints = {
        [USER_TYPE_CLEANS]: "/v1/cleaners/user/login/",
        [USER_TYPE_LAUNDRY]: "/v1/laundry/user/login/"
      };

      const { email, password } = values;

      api
        .post(endpoints[selectedDashboard], null, {
          type: "email",
          email: String(email).trim(),
          password: String(password).trim()
        })
        .then(res => {
          setSubmitting(false);
          dispatch(receiveLogin(res, selectedDashboard));
        })
        .catch(e => {
          setSubmitting(false);

          if (e.error.code === 400) {
            setErrors({ authError: "Unable to login" });
          } else {
            setErrors({ authError: "Invalid email or password" });
          }
        });
    },
    [dispatch, selectedDashboard]
  );

  return (
    <div className="fixed-centered">
      <div className="card">
        <div className="card-top">
          <Logo />
          {headerContent}
          {showUserTypeSwitch && (
            <div className={classNames("dont-print", css.tabs)}>
              <button
                type="button"
                onClick={onUserTypeSwitch(USER_TYPE_LAUNDRY)}
                className={classNames({
                  [css.active]: selectedDashboard === USER_TYPE_LAUNDRY
                })}
              >
                <span className={css.tab}>Laundry dashboard</span>
              </button>

              <button
                type="button"
                onClick={onUserTypeSwitch(USER_TYPE_CLEANS)}
                className={classNames({
                  [css.active]: selectedDashboard === USER_TYPE_CLEANS
                })}
              >
                <span className={css.tab}>Housekeeping dashboard</span>
              </button>
            </div>
          )}
        </div>

        <div className="card-body">
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={handleSubmit}
            validationSchema={loginValidationSchema}
            enableReinitialize
          >
            {({ isSubmitting, isValid, errors }) => (
              <Form className={css["form"]}>
                <label htmlFor="email">Email address</label>

                <div className="field">
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    placeholder={
                      selectedDashboard === USER_TYPE_LAUNDRY
                        ? "e.g. user@laundry.com"
                        : "e.g. cleaner@company.com"
                    }
                    autoComplete="email"
                  />
                  {errors.email && <DefaultError>{errors.email}</DefaultError>}
                </div>

                <label htmlFor="password">Password</label>

                <div className="field">
                  <Field
                    type="password"
                    name="password"
                    id="password"
                    autoComplete={"new-password"}
                    placeholder="Your password"
                  />
                  {errors.password && (
                    <DefaultError>{errors.password}</DefaultError>
                  )}
                </div>

                <div className="card-actions">
                  <Button
                    type="submit"
                    theme="solid"
                    disabled={!isValid || isSubmitting}
                  >
                    {submitButtonText || "Login"}
                  </Button>

                  {errors.authError && (
                    <DefaultError>{errors.authError}</DefaultError>
                  )}

                  <Link to={`${selectedDashboard}/forgot-password`}>
                    Forgot password
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
