export const RECEIVE_LOGIN = "RECEIVE_LOGIN";
export const RECEIVE_USER = "RECEIVE_USER";
export const LOGGED_OUT = "LOGGED_OUT";

export const receiveLogin = ({ token, user }, userType) => ({
  type: RECEIVE_LOGIN,
  payload: { token, user },
  meta: userType ? { userType } : {}
});

export const receiveUser = user => ({
  type: RECEIVE_USER,
  payload: { user }
});

export const logout = () => ({
  type: LOGGED_OUT
});
