import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { createLogger } from "redux-logger";

import logger from "../utils/logger.js";
import arrayMiddleware from "./middleware/arrayMiddleware";
import rootReducer from "./reducers/root";

const middlewares = [
  arrayMiddleware,
  createLogger({
    logger,
    collapsed: true
  })
].filter(Boolean);

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
