import { useCallback, useState } from "react";
import { USER_TYPE_CLEANS, USER_TYPE_LAUNDRY } from "utils/constants";

import { useQuery } from "utils/useQuery";
import { LoginForm } from "./LoginForm";

export const Login = () => {
  let query = useQuery();

  const [selectedDashboard, setSelectedDashboard] = useState(
    query.has(USER_TYPE_CLEANS) ? USER_TYPE_CLEANS : USER_TYPE_LAUNDRY
  );

  const handleUserTypeSwitch = useCallback(
    dashboard => () => {
      setSelectedDashboard(dashboard);
    },
    []
  );

  return (
    <LoginForm
      selectedDashboard={selectedDashboard}
      onUserTypeSwitch={handleUserTypeSwitch}
    />
  );
};
