import { format, parse } from "date-fns";
export const ISO_DATE = "y-MM-dd";

// TODO: this is parsing, not formatting
export const formatShortDate = date => format(date, "E do MMM");

export const formatFullDatetime = datetime =>
  format(datetime, "y-MM-dd kk:mm:ss");

export const formatMonth = date => format(date, "MMMM");

export const formatISODate = date => format(date, ISO_DATE);

export const parseISODate = dateString =>
  parse(dateString, "y-MM-dd", new Date());

export const parseISODateTime = dateString =>
  parse(dateString, "y-MM-dd'T'kk:mm:ss", new Date());

export const formatChartDate = date => format(date, "dd/MM");

export const formatHour = dateTime => {
  // Example: 2019-04-11T12:00:00
  // Return a substring starting after "T" and ending before the last ":" e.g. 12:00
  // This is required as date-fns has a bug in Safari where dates - even when they have a local
  // time portion are treated as UTC and are converted to local time when formatted.

  return dateTime.substring(
    dateTime.indexOf("T") + 1,
    dateTime.lastIndexOf(":")
  );
};
