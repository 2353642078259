import { combineActions, handleActions } from "redux-actions";
import { STORAGE_AUTH_TOKEN_KEY, STORAGE_USER_TYPE_KEY } from "utils/constants";
import { LOGGED_OUT, RECEIVE_LOGIN, RECEIVE_USER } from "../actions/user";

import api from "../../services/api";
import { localStorage } from "../../utils/storage";

const getInitialState = () => ({
  authToken: localStorage.getItem(STORAGE_AUTH_TOKEN_KEY),
  userType: localStorage.getItem(STORAGE_USER_TYPE_KEY),
  profile: {},
  localities: [],
  isAdmin: false
});

// Checks if user is logged in on first load and refresh
api.setToken(getInitialState().authToken);

const user = handleActions(
  {
    // Keep and set token in api
    [RECEIVE_LOGIN]: (state, { payload: { token }, meta }) => {
      localStorage.setItem(STORAGE_AUTH_TOKEN_KEY, token);
      localStorage.setItem(STORAGE_USER_TYPE_KEY, meta?.userType);
      api.setToken(token);

      return { ...state, authToken: token, userType: meta?.userType };
    },

    // Keep user profile data
    [combineActions(RECEIVE_USER, RECEIVE_LOGIN)]: (state, { payload }) => {
      return { ...state, profile: payload.user };
    },

    [LOGGED_OUT]: () => {
      localStorage.clear();
      api.setToken(null);

      return getInitialState();
    }
  },
  getInitialState()
);

export default user;
