import {
  ACCESS_TYPE_KEYLESS_DIGITAL_LOCK,
  ACCESS_TYPE_KEYNEST
} from "../../../utils/types";

import { useMemo } from "react";
import { checkIfCodeIsAvailable } from "../../../utils/properties";
import css from "./CleanView.module.scss";

export const Access = ({ cleanStart, property }) => {
  const isCodeVisible = useMemo(
    () => checkIfCodeIsAvailable(cleanStart),
    [cleanStart]
  );

  if (property.home_info?.location?.access_type === ACCESS_TYPE_KEYNEST) {
    return (
      <>
        <h3 className={css.label}>Keynest collection code</h3>
        <p className={css.value}>
          {isCodeVisible
            ? property.internal_key?.collection_code
            : "The code is only visible 24h before the clean"}
        </p>
        <h3 className={css.label}>Keynest store address</h3>
        <p className={css.value}>
          {property.internal_key?.keystore_location.name}
          <br />
          {property.internal_key?.keystore_location.street_address}
          <br />
          {property.internal_key?.keystore_location.description}
        </p>
      </>
    );
  }

  if (
    property.home_info?.location?.access_type ===
    ACCESS_TYPE_KEYLESS_DIGITAL_LOCK
  ) {
    return (
      <>
        <h3 className={css.label}>Digital lock code</h3>
        <p className={css.value}>
          {isCodeVisible
            ? property.home_info?.location?.digital_lock_code
            : "The code is only visible 24h before the clean"}
        </p>
      </>
    );
  }

  return (
    <>
      <h3 className={css.label}>Access type notes</h3>
      <p className={css.value}>
        {property.home_info?.location?.access_type_notes}
      </p>
      <h3 className={css.label}>Lockbox code</h3>
      <p className={css.value}>
        {isCodeVisible
          ? property.home_info?.location?.lockbox_code
          : "The code is only visible 24h before the clean"}
      </p>
    </>
  );
};
