import { connect, Field } from "formik";
import { useEffect, useMemo, useRef } from "react";

import classNames from "classnames";
import get from "lodash/get";
import has from "lodash/has";

export const FormGroup = connect(
  ({
    name,
    component,
    className,
    label,
    placeholder,
    renderAppend,
    helpText,
    children,
    readOnly,
    renderSideLabel,
    focus,
    formik: { errors },
    type,
    ...props
  }) => {
    const ref = useRef(null);
    const isCheckbox = type === "checkbox";
    const labelElement = useMemo(
      () => label && <label htmlFor={name}>{label}</label>,
      [label, name]
    );

    useEffect(() => {
      if (focus) {
        ref.current.focus();
      }
    }, [focus]);

    return (
      <div
        className={classNames("form-group", className)}
        id={`anchor-${name}`}
      >
        {!isCheckbox && labelElement}
        {renderSideLabel && renderSideLabel()}

        <div className="field">
          <Field
            innerRef={ref}
            id={name}
            name={name}
            placeholder={placeholder}
            component={component}
            autoComplete="off"
            readOnly={readOnly}
            className={classNames(
              isCheckbox ? "form-check-input" : "form-control",
              {
                "is-invalid": has(errors, name)
              }
            )}
            type={type}
            {...props}
          >
            {children}
          </Field>

          {isCheckbox && labelElement}

          {renderAppend && (
            <div className="input-group-append">
              <span className="input-group-text">{renderAppend()}</span>
            </div>
          )}
        </div>

        {helpText && <small className="form-text text-muted">{helpText}</small>}

        {has(errors, name) && (
          <div className="invalid-feedback">{get(errors, name)}</div>
        )}
      </div>
    );
  }
);
