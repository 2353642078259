import { receiveUser } from "data/actions/user";
import { userSelector } from "data/selectors/user";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "services/api";
import { useComponentIsMounted } from "utils/useComponentIsMounted";

export const useFetchUser = endpoint => {
  const user = useSelector(userSelector);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isMounted = useComponentIsMounted();

  const fetchUser = useCallback(async () => {
    try {
      setLoading(true);

      const user = await api.get(endpoint);
      dispatch(receiveUser(user));
    } finally {
      // If user was logged out, this component will be unmounted
      // and setting state will cause an error
      isMounted && setLoading(false);
    }
  }, [dispatch, endpoint, isMounted]);

  useEffect(() => {
    if (user?.profile?.name) {
      setLoading(false);
      return;
    }

    // Happens on page reload
    fetchUser();
  }, [fetchUser, user?.profile?.name]);

  return { loading };
};
