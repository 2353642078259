import {
  TASK_TYPE_PROPERTY,
  TASK_TYPE_REMOTE,
  TASK_TYPE_STANDARD
} from "../../../utils/types";

import { useMemo } from "react";
import css from "./Tasks.module.scss";

export const createTasksCollection = (
  remoteTasks,
  propertyTasks,
  hostCleaningInstructions,
  standardTasks
) => {
  remoteTasks = remoteTasks || [];
  propertyTasks = propertyTasks || [];
  standardTasks = standardTasks || [];

  return [
    ...[
      {
        id: `${TASK_TYPE_PROPERTY}:host_cleaning_instructions`,
        instruction: hostCleaningInstructions,
        type: TASK_TYPE_PROPERTY,
        completed: false
      }
    ].filter(({ instruction }) => Boolean(instruction)),

    ...remoteTasks.map(
      ({ id, name, description, image_required, external_url }) => ({
        id,
        name,
        instruction: description ? `${name}: ${description}` : name,
        image_required,
        external_url,
        type: TASK_TYPE_REMOTE
      })
    ),

    ...propertyTasks
      ?.filter(({ description }) => Boolean(description))
      ?.map(({ id, description, image_required, external_url }) => ({
        id: `${TASK_TYPE_PROPERTY}:single_host_cleaning_instruction:${id}`,
        instruction: description,
        image_required,
        external_url,
        type: TASK_TYPE_PROPERTY
      })),

    ...standardTasks?.map(({ id, description }) => ({
      id: `${TASK_TYPE_STANDARD}:${id}`,
      instruction: description,
      type: TASK_TYPE_STANDARD
    }))
  ];
};

const Tasks = ({
  remoteTasks,
  propertyTasks,
  hostCleaningInstructions,
  standardTasks
}) => {
  const tasks = useMemo(
    () =>
      createTasksCollection(
        remoteTasks,
        propertyTasks,
        hostCleaningInstructions,
        standardTasks
      ),
    [remoteTasks, propertyTasks, hostCleaningInstructions, standardTasks]
  );

  return (
    <ul className={css.Tasks}>
      {tasks.map(({ id, external_url, image_required, instruction }) => (
        <li className={css.Tasks__item} key={id}>
          {instruction}
          {image_required && " (image required)"}
          {external_url && (
            <>
              {" "}
              <a href={external_url} target="_blank" rel="noopener noreferrer">
                more info
              </a>
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Tasks;
