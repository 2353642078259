import { useMemo } from "react";
import {
  formatFullDatetime,
  formatHour,
  formatShortDate
} from "../../../../utils/date";

import { formatDistanceStrict } from "date-fns";
import { ReactComponent as ScheduledIcon } from "../../../../assets/icons/calendar.svg";
import { ReactComponent as CompletedIcon } from "../../../../assets/icons/check.svg";
import { ReactComponent as ClockIcon } from "../../../../assets/icons/clock.svg";
import { ReactComponent as PersonIcon } from "../../../../assets/icons/person.svg";
import { ReactComponent as StartedIcon } from "../../../../assets/icons/progress.svg";
import Copy from "../../../../components/Copy";
import { Pack } from "../../../../components/laundry/order/Pack";
import { parseDateTime } from "../../../../utils/dateParser";
import css from "./Order.module.scss";
import PropertyDetails from "./PropertyDetails";

const CLEAN_STATUS_STARTED = "started";
const CLEAN_STATUS_SCHEDULED = "scheduled";
const CLEAN_STATUS_COMPLETED = "completed";

const CLEAN_STATUS_ICONS = {
  [CLEAN_STATUS_STARTED]: <StartedIcon />,
  [CLEAN_STATUS_SCHEDULED]: <ScheduledIcon />,
  [CLEAN_STATUS_COMPLETED]: <CompletedIcon />
};

export const Order = ({ order }) => {
  const {
    delivery_window_start,
    delivery_window_end,
    status,
    property,
    laundry_packs,
    created_at,
    updated_at,
    notes,
    clean,
    order_type
  } = order;

  const deliveryTime = useMemo(() => {
    const start = formatHour(delivery_window_start);
    const end = formatHour(delivery_window_end);

    return (
      <strong data-testid="delivery-window">
        {start} - {end} {status === "failed" && "(Failed order)"}
      </strong>
    );
  }, [delivery_window_end, delivery_window_start, status]);

  // TODO: classnames
  const orderClasses = [css.order, status ? css[`order--${status}`] : ""].join(
    " "
  );

  return (
    <div className={orderClasses} data-testid="order">
      <div className={css.order__details} data-testid="order-details">
        <div className={css.order__detail}>
          <ClockIcon />
          {deliveryTime}
        </div>

        <PropertyDetails property={property} />

        <div className={css.order__detail}>
          <CleanerInfo clean={clean} />
        </div>

        <CleanStatus status={clean?.status} />
      </div>

      <div className={css.order__packs}>
        {notes && <p className={css.order__notes}>{notes}</p>}

        <div className={css["order__packs-list"]}>
          {order_type === "collection" ? (
            <p className={css["order__collection"]}>Collection only</p>
          ) : (
            laundry_packs.map(({ mattress_size, quantity }, key) => (
              <Pack
                mattressSize={mattress_size}
                quantity={quantity}
                key={key}
              />
            ))
          )}
        </div>

        <small className={css.order__extra}>
          <strong>{property.home_code}</strong> |{" "}
          {formatDistanceStrict(parseDateTime(created_at), new Date())} ago -{" "}
          {formatShortDate(parseDateTime(created_at), "Do MMM, HH:MM")}
        </small>

        <small className={css.order__extra}>
          <strong>Last updated: </strong>{" "}
          {formatFullDatetime(parseDateTime(updated_at))}
        </small>
      </div>
    </div>
  );
};

const CleanerInfo = ({ clean }) => (
  <>
    <PersonIcon />

    {clean ? (
      <>
        {clean.cleaner.name} (
        <a href={`tel:${clean.cleaner.phone}`}>{clean.cleaner.phone}</a>)
        <Copy text={clean.cleaner.phone} />
      </>
    ) : (
      "No cleaner assigned"
    )}
  </>
);

const CleanStatus = ({ status }) => {
  if (!status) {
    return null;
  }
  return (
    <div className={css.cleanstatus}>
      {CLEAN_STATUS_ICONS[status]}

      <p className={css[`cleanstatus--${status}`]}>Clean {status}</p>
    </div>
  );
};

export default Order;
