import { formatHour, formatISODate } from "../../../utils/date";
import { useCallback, useEffect, useRef, useState } from "react";

import { Access } from "./Access";
import Button from "components/Button";
import { CleanImages } from "./CleanImages";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import Editable from "../components/Editable";
import EditableStatus from "../components/EditableStatus";
import { Error } from "../../../components/common/Error";
import { Fridge } from "../../../components/clean/details/Fridge";
import { LaundryOrders } from "../../../components/clean/details/LaundryOrders";
import { WasteCollectionDays } from "../../../components/clean/details/WasteCollectionDays";
import Spinner from "../../../components/common/Spinner";
import api from "../../../services/api";
import { canEditClean, updateClean } from "../../../utils/cleans";
import Map from "../components/Map";
import Tasks from "../components/Tasks";
import { CleanImagesUpload } from "./CleanImagesUpload";
import css from "./CleanView.module.scss";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import classNames from "classnames";

const Row = ({ children, title, hiddenInPDF }) => (
  <>
    <h3 className={css.label} data-html2canvas-ignore={hiddenInPDF}>
      {title}
    </h3>
    <p className={css.value} data-html2canvas-ignore={hiddenInPDF}>
      {children}
    </p>
  </>
);

export const CleanView = () => {
  const { id } = useParams();
  const [clean, setClean] = useState(null);
  const [labelError, setLabelError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [standardTasks, setStandardTasks] = useState(null);
  const [images, setImages] = useState(null);
  const [exemplaryImages, setExemplaryImages] = useState(null);
  const [error, setError] = useState(null);
  const containerRef = useRef(null);

  const fetchImages = useCallback(() => {
    api
      .get(`/v1/cleaners/cleans/${id}/images`)
      .then(setImages)
      .catch(() => {
        setError(true);
      });
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const [
        newClean,
        newProperty,
        newTasks,
        newStandardTasks,
        newImages,
        newExemplaryImages
      ] = await Promise.all(
        [
          "",
          "/property",
          "/tasks",
          "/standard_tasks",
          "/images",
          "/property/exemplary_images"
        ].map(endpoint => api.get(`/v1/cleaners/cleans/${id}${endpoint}`))
      );

      setClean(newClean);
      setProperty(newProperty);
      setTasks(newTasks);
      setStandardTasks(newStandardTasks);
      setImages(newImages);
      setExemplaryImages(newExemplaryImages);
    };

    fetchData()
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const exportPDF = useCallback(() => {
    const options = {
      margin: 1,
      filename: `clean-${formatISODate(new Date(clean.start))}-${
        property.home_code
      }.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      enableLinks: true,
      jsPDF: {
        unit: "in",
        format: "a3",
        orientation: "portrait",
        compressPDF: true
      }
    };

    html2pdf().set(options).from(containerRef.current).save();
  }, [clean, property]);

  const handleCleanUpdate = useCallback(
    label => {
      setLabelError(false);
      updateClean(id, { label }).then(res => {
        if (res.error) {
          setLabelError(true);
          return;
        }

        setClean(res);
      });
    },
    [id]
  );

  if (loading) {
    return <Spinner centered />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <div ref={containerRef}>
      <div className={css["clean-header"]}>
        <Button testid="btn-download-csv" onClick={exportPDF}>
          Download PDF
          <DownloadIcon />
        </Button>
      </div>

      <div className={css.container}>
        <div className={css.tab} data-testid="section-clean-details">
          <h2 className={css.header}>Clean</h2>
          <div className={css.grid}>
            <Row title="Date">{formatISODate(new Date(clean.start))}</Row>
            <Row title="Time">{formatHour(clean.start)}</Row>
            <Row title="Duration">{clean.duration}</Row>
          </div>
          <div>
            <div
              className={css["editable-value"]}
              data-testid="status-editable"
            >
              <h3 className={css.label}>Status</h3>
              <EditableStatus
                status={clean.status}
                cleanId={clean.id}
                onSubmit={setClean}
              />
            </div>
            <div className={css["editable-value"]}>
              <h3 className={css.label}>Clean label</h3>
              <Editable
                error={labelError}
                value={clean.label}
                onSubmit={handleCleanUpdate}
              />
            </div>
          </div>
          <div className={css.grid}>
            <Row title="Clean type">{clean.clean_type}</Row>
            <Row title="Linen delivery">
              <LaundryOrders orders={clean.laundry_orders} />
            </Row>
            <Row title="Items in the fridge">
              <Fridge
                instructions={property.host_preferences?.fridge_cleaning}
              />
            </Row>
            <Row title="Cleaning equipment location">
              {property.home_info?.cleaning?.cleaning_equipment_location}
            </Row>
            <Row title="Vacuum location">
              {property.home_info?.cleaning?.vacuum_location}
            </Row>
            <Row title="Recycling instructions">
              {property.home_info?.cleaning?.recycling_instructions}
            </Row>
            <Row title="Waste collection day(s)">
              <WasteCollectionDays
                value={property.home_info?.cleaning?.waste_collection_days}
              />
            </Row>
          </div>
        </div>

        <div className={css.tab}>
          <h2 className={css.header}>Property</h2>
          <div className={css.grid}>
            <h3 className={css.label}>Property address</h3>
            <p className={css.value}>
              {property.street}, {property.postcode} {property.locality?.name}
              <br />
              Unit floor: {property.home_info?.unit_floor}
            </p>
          </div>
          <div data-html2canvas-ignore="true">
            <Map
              lat={clean.property_location?.lat}
              lng={clean.property_location?.lng}
            />
          </div>

          <div className={css.grid}>
            <Row title="Property size">
              {property.bedrooms} bedrooms, {property.bathrooms} bathrooms
            </Row>

            {property.home_info?.location?.access_map && (
              <Row title="Photo access guide" hiddenInPDF>
                <a
                  href={property.home_info?.location?.access_map}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download photo access guide
                </a>
              </Row>
            )}
            <Row title="Property location notes">
              {property.home_info?.location?.property_location_notes}
            </Row>
            <Row title="Lockbox type">
              {property.home_info?.location?.access_type}
            </Row>

            <Access cleanStart={clean.start} property={property} />

            <h3 className={css.label}>Wi-Fi</h3>
            <div>
              <p className={css.value}>
                Wi-Fi name: {property.home_info?.wifi_network_name}
              </p>
              <p className={css.value}>
                Wi-Fi password: {property.home_info?.wifi_password}
              </p>
            </div>
            <Row title="Parking notes">{property.home_info?.parking_notes}</Row>
            <Row title="Nearest transport station">
              {property.home_info?.location?.nearest_station}
            </Row>
            <Row title="Heating control location">
              {property.home_info?.maintenance?.heating_control_location}
            </Row>
            <Row title="Heating notes">
              {property.home_info?.maintenance?.heating_notes}
            </Row>
            <Row title="Door type">{property.home_info?.door?.type}</Row>
            <Row title="Door notes">{property.home_info?.door?.note}</Row>
          </div>
        </div>

        <div className={css.tab}>
          <h2 className={css.header}>Tasks list</h2>
          <Tasks
            propertyTasks={property?.home_info?.cleaning_tasks}
            remoteTasks={tasks}
            standardTasks={standardTasks}
            hostCleaningInstructions={
              property.home_info?.cleaning.host_cleaning_instructions
            }
          />
        </div>

        <div
          className={classNames(css.tab, "dont-print")}
          data-html2canvas-ignore="true"
        >
          <CleanImages images={images} title="Images" collapsed />

          <CleanImages
            images={exemplaryImages}
            title="Exemplary Images"
            collapsed
          />

          {canEditClean(clean) && (
            <CleanImagesUpload onUploadFinish={fetchImages} />
          )}
        </div>
      </div>
    </div>
  );
};
