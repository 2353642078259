import { useCallback, useState } from "react";
import { formatHour, formatISODate } from "../../../utils/date";

import classNames from "classnames";
import { Link } from "react-router-dom";
import { updateClean } from "utils/cleans";
import Editable from "./Editable";
import css from "./List.module.scss";

export const ListItem = ({ clean: cleanFromProps }) => {
  const [clean, setClean] = useState(cleanFromProps);
  const [labelError, setLabelError] = useState(false);

  const handleCleanUpdate = useCallback(
    label => {
      setLabelError(false);
      updateClean(clean.id, { label }).then(res => {
        res.error ? setLabelError(true) : setClean(res);
      });
    },
    [clean.id]
  );

  const { id, start, status, label, property } = clean;

  return (
    <tr className={css.row} key={id} data-testid="clean-item">
      <td className={css.cell} data-testid="clean-date">
        <Link className={css.link} to={`view/${id}`}>
          {formatISODate(new Date(start))}
        </Link>
      </td>
      <td className={css.cell} data-testid="clean-hour">
        <Link className={css.link} to={`view/${id}`}>
          {formatHour(start)}
        </Link>
      </td>
      <td className={classNames(css.cell, status)} data-testid="clean-status">
        <Link className={css.link} to={`view/${id}`}>
          {status}
        </Link>
      </td>
      <td className={css.cell}>
        <Link className={css.link} to={`view/${id}`}>
          {property.home_code}
        </Link>
      </td>
      <td className={css.cell}>
        <Link className={css.link} to={`view/${id}`}>
          {property.street}, {property.postcode} {property.locality?.name}
        </Link>
      </td>
      <td className={css.cell} data-testid="clean-label">
        <Editable
          inplace
          value={label}
          error={labelError}
          onSubmit={handleCleanUpdate}
          emptyValueText="Add label"
        />
      </td>
    </tr>
  );
};
