import { Redirect, Route, useLocation } from "react-router-dom";

import React from "react";
import { useSelector } from "react-redux";

export const AuthRoute = ({ component, render, path }) => {
  const location = useLocation();
  const isLoggedIn = useSelector(state => !!state.user.authToken);

  return isLoggedIn ? (
    <Route
      path={path}
      render={props => React.createElement(component || render, props)}
    />
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: location }
      }}
    />
  );
};

export default AuthRoute;
