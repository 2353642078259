import { endOfDay, addYears } from "date-fns";

import { Orders } from "./Orders";

export const Upcoming = () => {
  const currentDate = new Date();

  return (
    <Orders
      initialStartDate={endOfDay(currentDate)}
      initialEndDate={addYears(currentDate, 1)}
      csvLabel="upcoming_orders.csv"
      pickerMinDate={currentDate}
    />
  );
};
