import classNames from "classnames";
import css from "./Spinner.module.scss";

export const Spinner = ({ children, centered }) => (
  <div
    className={classNames(css.spinner, css["spinner--cover"], {
      [css["spinner--centered"]]: centered
    })}
  >
    {children}
  </div>
);

export default Spinner;
