import classNames from "classnames";
import { identity, isString } from "lodash";

import css from "./Button.module.scss";

const Button = ({
  children,
  onClick = identity,
  confirmAlert = false,
  testid = "",
  type = "button",
  theme = "primary", // primary, secondary, solid, link, text
  className,
  disabled = false,
  hiddenInPDF = false
}) => {
  const buttonClasses = classNames([
    css.button,
    css[`button__${theme}`],
    className
  ]);

  const handleClick = e => {
    if (confirmAlert) {
      if (
        window.confirm(isString(confirmAlert) ? confirmAlert : "Are you sure?")
      ) {
        onClick(e);
      }
    } else {
      onClick(e);
    }
  };

  return (
    <button
      onClick={handleClick}
      className={buttonClasses}
      testid={testid}
      disabled={disabled}
      type={type === "submit" ? "submit" : "button"} /* fixes eslint warnings */
      data-testid={testid}
      data-html2canvas-ignore={hiddenInPDF}
    >
      {children}
    </button>
  );
};

export default Button;
