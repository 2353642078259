const ENABLE_LOG = !["production", "test"].includes(process.env.NODE_ENV);

export const group = (...args) => {
  if (!ENABLE_LOG) return;
  console.group(...args);
};

export const groupCollapsed = (...args) => {
  if (!ENABLE_LOG) return;
  console.groupCollapsed(...args);
};

export const groupEnd = () => {
  if (!ENABLE_LOG) return;
  console.groupEnd();
};

export const log = (...args) => {
  if (!ENABLE_LOG) return;
  console.log(...args);
};

export const info = (...args) => {
  if (!ENABLE_LOG) return;
  console.info(...args);
};

export const warn = (...args) => {
  if (!ENABLE_LOG) return;
  console.warn(...args);
};

export const error = (...args) => {
  if (!ENABLE_LOG) return;
  console.error(...args);
};

const all = {
  group,
  groupCollapsed,
  groupEnd,
  log,
  warn,
  info,
  error
};
export default all;
