import { flatten, groupBy, sortBy, sumBy } from "lodash";

import { formatISODate } from "./date";
import { parseDateTime } from "./dateParser";

// Sorts laundry packs alphabetically by mattress size
export const sortPacks = packs => {
  return sortBy(packs, "mattress_size");
};

export const mergeOrdersByProperty = orders => {
  let ordersHash = {};

  // Loop over each order adding to the hash by property id
  // If the property id of the order already exists - merge it's packs with the existing packs
  // Otherwise add the order to the hash
  orders.forEach(order => {
    ordersHash[order.property.id] = ordersHash[order.property.id]
      ? {
          ...order,
          laundry_packs: sortPacks([
            ...ordersHash[order.property.id].laundry_packs,
            ...order.laundry_packs
          ])
        }
      : {
          ...order,
          laundry_packs: sortPacks(order.laundry_packs)
        };
  });

  // Turn the hash object back into an array of orders with merged packs
  return Object.keys(ordersHash).map(order => ordersHash[order]);
};

export const groupOrders = orders => {
  const ordersByDay = groupBy(orders, order =>
    formatISODate(parseDateTime(order.delivery_window_start))
  );

  const formattedOrders = Object.keys(ordersByDay).map(day => {
    const orders = ordersByDay[day];

    return {
      date: parseDateTime(orders[0].delivery_window_start),
      orders,
      packsTotal: groupTotalPacks(orders)
    };
  });

  return formattedOrders;
};

const groupTotalPacks = orders => {
  const allLaundryPacks = flatten(orders.map(order => order.laundry_packs));

  const byMattressSize = groupBy(
    allLaundryPacks,
    ({ mattress_size }) => mattress_size
  );

  const packs = Object.keys(byMattressSize).map(mattress_size => ({
    mattress_size,
    quantity: sumBy(byMattressSize[mattress_size], pack => pack.quantity)
  }));

  return sortPacks(packs);
};
