/*
Implementation of simple and safe methods for parsing date strings.

In general in our apps we are dealing with three date formats:

- ISO 8601 with "Z" zone designator for UTC offset, like "2019-10-31T08:15:00Z".
Usually such format is used for dates that are created on the server side, like
`created_at`, `updated_at` timestamps.

- ISO 8601 without "Z", like "2019-10-31T08:15:00". Such format is usually used
for "local" times, like `clean.start`, `clean.complete` etc. Timezone is this
case could be omitted because we are always displaying such dates in a local time.

- ISO 8601 calendar dates, like "2019-12-24".

IMPORTANT:
Please keep it mind that we should never use `new Date(string)` for parsing such
dates, because on some platforms (like React Native) it could produce
unpredictable results.

Some more details about ISO 8601 date format: https://en.wikipedia.org/wiki/ISO_8601
Nice article about JavaScript Date quirks: https://css-tricks.com/everything-you-need-to-know-about-date-in-javascript
Documentation for JavaScript Date: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
 */

// TODO: Bundle it as npm library (@airsorted/date-parser) and reuse it in other frontend apps

// For example:
// * 2019-10-29T14:30:00  - for local dates, without timezone, like `clean.start`
// * 2018-06-01T00:30:00Z - for UTC dates, like `clean.created_at`
export const DATE_TIME_REGEXP =
  /^(?<years>\d{4})-(?<months>\d{2})-(?<days>\d{2})T(?<hours>\d{2}):(?<minutes>\d{2}):(?<seconds>\d{2})(.\d{3})?Z?$/;

// Short date format like: 2019-10-30 (without time)
export const DATE_REGEXP = /^(?<years>\d{4})-(?<months>\d{2})-(?<days>\d{2})$/;

export const tryParse = (value, { with: pattern, onSuccess }) => {
  if (!value) {
    return null;
  }

  const match = pattern.exec(value.toString());

  if (!match) {
    throw new Error(
      `The given string "${value}" has unsupported datetime format for pattern: "${pattern}"`
    );
  }

  return onSuccess(match.groups);
};

// Transforms `{ years: "2019", months: "10", ... }` to `{ years: 2019, months: 10, ... }`
const castToInteger = groups =>
  Object.entries(groups).reduce(
    (result, [field, value]) => ({
      ...result,
      [field]: parseInt(value)
    }),
    {}
  );

const isUTC = value => value.endsWith("Z");

// Safe implementation of parsing datetime strings that are provided by core-api.
export const parseDateTime = dateTimeString =>
  tryParse(dateTimeString, {
    with: DATE_TIME_REGEXP,
    onSuccess: groups => {
      const { years, months, days, hours, minutes, seconds } =
        castToInteger(groups);

      const args = [years, months - 1, days, hours, minutes, seconds];

      if (isUTC(dateTimeString)) {
        return new Date(Date.UTC(...args));
      } else {
        return new Date(...args);
      }
    }
  });

// Safe implementation of parsing date strings.
export const parseDate = dateString =>
  tryParse(dateString, {
    with: DATE_REGEXP,
    onSuccess: groups => {
      const { years, months, days } = castToInteger(groups);
      return new Date(years, months - 1, days);
    }
  });
