import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { useFetchUser } from "components/useFetchUser";
import Previous from "scenes/cleans/list/Previous";
import Upcoming from "scenes/cleans/list/Upcoming";
import { Account } from "../../../scenes/cleans/account/Account";
import { useApiErrorsInterceptor } from "utils/useApiErrorsInterceptor";
import { CleanView } from "../../../scenes/cleans/view/CleanView";
import { Spinner } from "../../common/Spinner";
import { Header } from "./Header";
import css from "./MainContainer.module.scss";

export const MainContainer = () => {
  useApiErrorsInterceptor();
  let { path } = useRouteMatch();
  const { loading } = useFetchUser("/v1/cleaners/user/me");

  if (loading) {
    return <Spinner centered />;
  }

  return (
    <>
      <Header />

      <div className={css.container}>
        <Switch>
          <Redirect exact from="/cleans" to="/cleans/upcoming" />
          <Route exact path={`${path}/upcoming`} component={Upcoming} />
          <Route exact path={`${path}/previous`} component={Previous} />
          <Route exact path={`${path}/view/:id`} component={CleanView} />
          <Route
            exact
            path={[`${path}/account`, `${path}/delete-account`]}
            component={Account}
          />
        </Switch>
      </div>
    </>
  );
};
