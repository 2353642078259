export const STORAGE_USER_TYPE_KEY = "user_type";
export const STORAGE_AUTH_TOKEN_KEY = "auth_token";

export const USER_TYPE_LAUNDRY = "laundry";
export const USER_TYPE_CLEANS = "cleans";
export const MAX_HOURS_FOR_EDITION_AFTER_END = 24;

export const CLEAN_STATUS_REQUESTED = "requested";
export const CLEAN_STATUS_CONFIRMED = "confirmed";
export const CLEAN_STATUS_DECLINED = "declined";
export const CLEAN_STATUS_STARTED = "started";
export const CLEAN_STATUS_COMPLETED = "completed";
export const CLEAN_STATUS_PAID = "paid";

export const CLEANS_DEFAULT_LIMIT = 20;

const IMAGE_TYPE_RECEIPT = "receipt";
const IMAGE_TYPE_EMERGENCY = "emergency";
const IMAGE_TYPE_DAMAGE = "damage";
const IMAGE_TYPE_FAULT = "fault";
const IMAGE_TYPE_MAINTENANCE = "maintenance";
const IMAGE_TYPE_MAINTENANCE_JOB_REPORT = "maintenance_job_report";
const IMAGE_TYPE_TASK = "task";
const IMAGE_TYPE_KEYS_IN_LOCKBOX = "keys_in_lockbox";
const IMAGE_TYPE_OTHER = "other";

const IMAGE_TYPE_LOCATION_BATHROOM = "location_bathroom";
const IMAGE_TYPE_LOCATION_BEDROOM = "location_bedroom";
const IMAGE_TYPE_LOCATION_KITCHEN = "location_kitchen";
const IMAGE_TYPE_LOCATION_LIVING_ROOM = "location_living_room";
const IMAGE_TYPE_LOCATION_OUTDOOR = "location_outdoor";
const IMAGE_TYPE_LOCATION_GENERAL = "location_general";
const IMAGE_TYPE_LOCATION_AMENITIES = "location_amenities";

const IMAGE_TYPE_PRE_SPOT_CHECK = "pre_spot_check";
const IMAGE_TYPE_POST_SPOT_CHECK = "post_spot_check";
const IMAGE_TYPE_START_CONFIRMATION = "image_type_start_confirmation";

export const IMAGE_TYPES_OPTIONS = {
  [IMAGE_TYPE_RECEIPT]: "Receipt",
  [IMAGE_TYPE_EMERGENCY]: "Emergency",
  [IMAGE_TYPE_DAMAGE]: "Damage",
  [IMAGE_TYPE_FAULT]: "Fault",
  [IMAGE_TYPE_MAINTENANCE]: "Maintenance",
  [IMAGE_TYPE_MAINTENANCE_JOB_REPORT]: "Maintenance job report",
  [IMAGE_TYPE_TASK]: "Task",
  [IMAGE_TYPE_KEYS_IN_LOCKBOX]: "Keys in lockbox",
  [IMAGE_TYPE_OTHER]: "Other",

  [IMAGE_TYPE_LOCATION_BATHROOM]: "Bathroom",
  [IMAGE_TYPE_LOCATION_BEDROOM]: "Bedroom",
  [IMAGE_TYPE_LOCATION_KITCHEN]: "Kitchen",
  [IMAGE_TYPE_LOCATION_LIVING_ROOM]: "Living room",
  [IMAGE_TYPE_LOCATION_OUTDOOR]: "Outdoor",
  [IMAGE_TYPE_LOCATION_GENERAL]: "General",
  [IMAGE_TYPE_LOCATION_AMENITIES]: "Amenities"
};

export const IMAGE_TYPES = {
  ...IMAGE_TYPES_OPTIONS,
  [IMAGE_TYPE_PRE_SPOT_CHECK]: "Pre spot check",
  [IMAGE_TYPE_POST_SPOT_CHECK]: "Post spot check",
  [IMAGE_TYPE_START_CONFIRMATION]: "Start confirmation"
};
