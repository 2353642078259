import { useHistory, useParams } from "react-router-dom";
import { USER_TYPE_CLEANS, USER_TYPE_LAUNDRY } from "utils/constants";
import { logout, receiveLogin } from "../../data/actions/user";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "utils/useQuery";
import Spinner from "../../components/common/Spinner";
import api from "../../services/api";

export const TokenLogin = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  let query = useQuery();
  const { token } = useParams();

  useEffect(() => {
    const endpoints = {
      [USER_TYPE_CLEANS]: "/v1/cleaners/user/login/",
      [USER_TYPE_LAUNDRY]: "/v1/laundry/user/login/"
    };

    const selectedDashboard = query.has(USER_TYPE_CLEANS)
      ? USER_TYPE_CLEANS
      : USER_TYPE_LAUNDRY;

    api
      .post(endpoints[selectedDashboard], null, { type: "token", token })
      .then(res => {
        dispatch(receiveLogin(res, selectedDashboard));
        history.push("/");
      })
      .catch(() => {
        dispatch(logout());
        history.push("/");
      });
  }, [dispatch, history, query, token]);

  return <Spinner centered />;
};
