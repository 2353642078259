import { noop } from "lodash";

import { STORAGE_AUTH_TOKEN_KEY, STORAGE_USER_TYPE_KEY } from "./constants";

const PERSIST = "--persist";
const PREFIX = "supplier:";

function wrapStorage(storage) {
  const buildKey = key => `${PREFIX}${key}`;

  return {
    getItem: key => storage.getItem(buildKey(key)),

    setItem: (key, val) => storage.setItem(buildKey(key), val),

    removeItem: key => storage.removeItem(buildKey(key)),

    clear: () => {
      const authTokenKey = buildKey(STORAGE_AUTH_TOKEN_KEY);

      const keysToClear = Object.keys(storage).filter(
        key =>
          key !== authTokenKey && // Auth token has to be removed as last one, otherwise loop breaks as user gets logged out instantly
          !key.endsWith(PERSIST) && // keep keys marked as persistent
          key.startsWith(PREFIX) // keep keys from outside of app (chrome plugins etc.)
      );

      keysToClear.forEach(key => storage.removeItem(key));
      storage.removeItem(authTokenKey);
      storage.removeItem(STORAGE_USER_TYPE_KEY);
    },

    dump: () => {
      const keys = Object.keys(storage);

      return keys.reduce((acc, key) => {
        acc[key] = storage.getItem(key);

        return acc;
      }, {});
    }
  };
}

function isSupported(storage) {
  try {
    storage.setItem("ls-compat", "true");
    storage.removeItem("ls-compat");

    return true;
  } catch (error) {
    return false;
  }
}

export const NOOP_STORAGE = {
  getItem: noop,
  setItem: noop,
  removeItem: noop,
  clear: noop
};

export const localStorage = isSupported(window.localStorage)
  ? wrapStorage(window.localStorage)
  : NOOP_STORAGE;
