import { LoginForm } from "scenes/login/LoginForm";
import { USER_TYPE_CLEANS } from "utils/constants";

export const DeleteAccount = () => {
  return (
    <div className="fixed-centered">
      <div className="card">
        <LoginForm
          selectedDashboard={USER_TYPE_CLEANS}
          showUserTypeSwitch={false}
          submitButtonText="Delete account"
          headerContent={
            <h4>
              This form will allow you to completely remove your account and
              personal data related to Houst's Houstkeeping app.
              <br />
              Your request may take a few days and your account will be inactive
              immediately. This step is irreversible.
              <br />
            </h4>
          }
        />
      </div>
    </div>
  );
};
