import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

import classNames from "classnames";
import { logout } from "../../../data/actions/user";
import { userSelector } from "../../../data/selectors/user";
import Button from "../../Button";
import css from "./MainContainer.module.scss";

export const Header = () => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <div className={css.header}>
      <div className={css.container}>
        <div className={css.details}>
          <div>
            <h1 className={css["details__title"]}>{user.profile?.name}</h1>
            <p className={css["details__description"]}>
              Houst laundry order dashboard
            </p>
          </div>

          <div className={classNames("dont-print", css["details__actions"])}>
            <Link to="/laundry/account">
              <Button theme="solid" testid="change-password-button">
                Account
              </Button>
            </Link>

            <Button theme="solid" testid="logout-button" onClick={handleLogout}>
              Logout
            </Button>
          </div>
        </div>

        <div className={classNames("dont-print", css.tabs)}>
          <NavLink
            activeClassName={css.active}
            to="/laundry/upcoming"
            isActive={(match, { pathname }) => pathname === "/" || match}
          >
            <span className={css.tab}>Upcoming orders</span>
          </NavLink>

          <NavLink activeClassName={css.active} to="/laundry/previous">
            <span className={css.tab}>Previous orders</span>
          </NavLink>

          <NavLink activeClassName={css.active} to="/laundry/forecast">
            <span className={css.tab}>Forecast</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
