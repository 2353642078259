import { differenceInHours } from "date-fns";
import api from "../services/api";
import {
  MAX_HOURS_FOR_EDITION_AFTER_END,
  CLEANS_DEFAULT_LIMIT,
  CLEAN_STATUS_STARTED
} from "./constants";
import { parseDateTime } from "./dateParser";

export const getCleans = (url, offset) =>
  api
    .get(url, {
      offset,
      limit: CLEANS_DEFAULT_LIMIT
    })
    .then(list => ({ list }))
    .catch(error => ({ error }));

export const updateClean = (id, values) =>
  api
    .patch(`/v1/cleaners/cleans/${id}`, null, values)
    .then(res => res)
    .catch(error => error);

export function hasStatus({ status }, ...statuses) {
  return statuses.includes(status);
}

export function canEditClean(clean, now = new Date()) {
  if (!clean.completed_at) {
    return hasStatus(clean, CLEAN_STATUS_STARTED);
  }

  return (
    differenceInHours(now, parseDateTime(clean.completed_at)) <=
    MAX_HOURS_FOR_EDITION_AFTER_END
  );
}
