import { useSelector } from "react-redux";
import { isCustomPresetSelector } from "../../../data/selectors/user";
import css from "./account.module.scss";
import { ChangePassword } from "./ChangePassword";
import { ShowPreset } from "./components/ShowPreset";

export const Account = () => {
  const isCustomPreset = useSelector(isCustomPresetSelector);

  return (
    <div className={css["account-container"]}>
      <h3 className={css["preset-title"]}>
        Current color preset: {isCustomPreset ? "Custom" : "Default"}
      </h3>

      <p>Contact Houst if you wish to change your color preset.</p>

      <ShowPreset />

      <div className={css.separator} />

      <h3 className={css["account-title"]}>Change password</h3>

      <ChangePassword />
    </div>
  );
};
